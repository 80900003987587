
class LinkService {
  getpythonlink() {
    return 'https://api-fda.ponnipa.in.th';
    // return 'http://localhost:8081';
  }
}

export default new LinkService();

